<template>
  <div class="box">
    <div class="box-title">
      <div class="sub-title">{{$t('word.siteName')}}</div>
      <h3 class="title">{{$t('auth.m_findPassword.name')}}</h3>
    </div>
    <div class="box-body">
      <el-alert
        class="error-tip"
        v-show="false"
        title="Email格式有误"
        effect="dark"
        type="error">
      </el-alert>
      <el-form ref="form" :model="model" :rules="rules">
        <template v-if="step === 1">
          <el-form-item label="" prop="email">
            <el-input type="text" v-model="model.email" :placeholder="$t('auth.m_findPassword.inputRegisterEmail')" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item>
            <el-row :gutter="12">
              <el-col :span="12">
                <el-button icon="el-icon-arrow-left" style="width:100%;" @click="onBack">{{$t('action.back')}}</el-button>
              </el-col>
              <el-col :span="12">
                <el-button type="primary" style="width:100%;" @click="onSendEmail">{{$t('auth.action.applyRestPassword')}}</el-button>
              </el-col>
            </el-row>
          </el-form-item>
        </template>
        <template v-if="step === 2">
          <el-form-item label="" prop="code">
            <el-input v-model="model.code" :placeholder="$t('auth.m_register.verificationCode')" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="" prop="pass">
            <el-input type="password" v-model="model.pass" :placeholder="$t('auth.m_register.inputPassword')" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="" prop="pass2">
            <el-input type="password" v-model="model.pass2" :placeholder="$t('auth.m_register.inputPasswordRepeat')" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" style="width:100%;" @click="onSubmit">{{$t('auth.action.submit')}}</el-button>
          </el-form-item>
        </template>
      </el-form>
    </div>
  </div>
</template>

<script>
import * as commonApi from '@/api/common'
import * as userApi from '@/api/user'
import pattern from '@/config/pattern'
export default {
  data() {
    let validateCode= (rule, value, callback) => {
      let passkey = parseInt(value) * 9527;
      if (passkey !== this.model.passWordKey) {
        callback(new Error('验证码错误'));
      } else {
        callback();
      }
    };
    let validatePwd2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.model.pass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      step: 1,
      useAgreementShow: false,
      useAgreementIsAgree: false,
      sendEmail: {
        loading: false
      },
      model: {
        email: '',
        code: '',
        pass: '',
        pass2: '',
        passWordKey: '',
      },
      rules: {
        email: [
          {required: true, message: '请输入', trigger: 'blur'},
          {pattern: pattern.email, message: '邮箱格式不正确', trigger: 'blur'}
        ],
        code: [
          {required: true, message: '请输入', trigger: 'blur'},
          {validator: validateCode, trigger: 'blur'}
        ],
        pass: [
          {required: true, message: '请输入', trigger: 'blur'}
        ],
        pass2: [
          {required: true, message: '请输入', trigger: 'blur'},
          {validator: validatePwd2, trigger: 'blur'}
        ]
      }
    }
  },
  methods: {
    onSendEmail() {
      this.$refs['form'].validate(async valid => {
        if (!valid) {
          return;
        }
        this.sendEmail.loading = true;
        let { code, msg, passWordKey, useKey } = await commonApi.sendEmail({
          email: this.model.email,
          do: 'reg'
        })
        this.sendEmail.loading = false;
        if(code === '1') {
          this.model.passWordKey = parseInt(passWordKey);
          this.model.useKey = useKey;
          this.$message.success('验证码已发送您的邮箱,请查收');
          this.step = 2
        } else {
          this.$message.error('发送失败,请检查邮箱是否正确');
        }
      });
    },
    async onSubmit() {
      this.$refs['form'].validate(async valid => {
        if (!valid) {
          return;
        }
        let res = await userApi.ModifyPwdOnly({
          Email: this.model.email,
          newPwd: this.model.pass,
          GuidUseKey: this.model.useKey
        });
        if (res.result && res.result.status) {
          this.$message.success('密码修改成功,请登录');
        } else {
          this.$message.error(res.result.message);
        }
      });
    },
    onBack() {
      this.$router.push('/auth?type=login')
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  width: 430px;
  .box-title {
    margin-bottom: 40px;
    .sub-title {
      margin-bottom: 16px;
      color: #858e9b;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 2px;
    }
    .title {
      color: rgb(31, 41, 46);
      font-size: 32px;
      font-weight: normal;
    }
  }
  .error-tip {
    margin-bottom: 22px;
  }
  .tip {
    text-align: right;
    /deep/ .el-button {
      padding: 0;
    }
  }
}
</style>
