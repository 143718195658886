<template>
  <div class="auth">
    <div class="left"></div>
    <div class="main">
      <transition name="el-fade-in-linear" mode="out-in">
      <login v-if="type === 'login'"></login>
      <register v-if="type === 'register'"></register>
      <find-password v-if="type === 'find-password'"></find-password>
      </transition>
    </div>
  </div>
</template>

<script>
import Login from './child/login'
import Register from './child/register'
import FindPassword from './child/find-password'
export default {
  components: {
    Login,
    Register,
    FindPassword
  },
  data() {
    return {
      type: ''
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: function(to, from) {
        this.type = to.query.type || 'login'
      }
    }
  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
  .auth {
    display: flex;
    align-items: stretch;
    height: 100%;
    background-color: #fff;
    .left {
      flex: 0 0 auto;
      width: 450px;
      background-image: url('./images/1.jpg');
      background-position: center;
      background-size: cover;
    }
    .main {
      flex: 1 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
</style>
