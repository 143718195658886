export default {
  email: /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,5})$/, // 邮箱
  phone: /^1[0-9]{10}$/, // 手机号码
  tel: /^([0-9]{3,4}-)?[0-9]{7,8}$/, // 电话号码
  phoneOrTel: /(^1[0-9]{10}$)|(^([0-9]{3,4}-)?[0-9]{7,8}$)/, // 手机或电话
  url: /^http[s]?:\/\/.*/, // url
  uri: /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/, // uri
  lowerCase: /^[a-z]+$/, // 小写字母
  upperCase: /^[A-Z]+$/ // 大写字母
}
