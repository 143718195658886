<template>
  <div class="box">
    <div class="box-title">
      <div class="sub-title">{{$t('word.siteName')}}</div>
      <h3 class="title">{{$t('auth.m_login.name')}}</h3>
    </div>
    <div class="box-body">
      <el-alert
        class="err-tip"
        v-show="err.show"
        :title="err.msg"
        effect="dark"
        type="error">
      </el-alert>
      <el-form :model="model">
        <el-form-item label="">
          <el-input v-model="model.userName" type="text" :placeholder="$t('auth.m_login.account')" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="">
          <el-input v-model="model.passWord" type="password" :placeholder="$t('auth.m_login.password')" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="">
          <DragVerify
            :width="430" 
            :height="40" 
            text="滑动验证" 
            success-text="验证通过"
            :circle="false"
            progressBarBg="#66cc66"
            textSize="14px"
            handlerIcon="el-icon-right"
            successIcon="el-icon-check"
            @passcallback="onPasscallback">
          </DragVerify>
        </el-form-item>

        <!-- <el-form-item label="">
          <el-row :gutter="12">
            <el-col :span="16">
              <el-input
                v-model="model.verificationCode"
                type="text"
                :placeholder="$t('auth.m_login.verificationCode')"
                autocomplete="off"
                @keyup.enter.native="onLogin">
              </el-input>
            </el-col>
            <el-col :span="8" style="height: 40px;">
              <img class="vcode-img" :src="vierificationCodeUrl" @click="getVierificationCode">
            </el-col>
          </el-row>
        </el-form-item> -->

        <el-form-item>
          <el-button type="primary" style="width:100%;"  :disabled="!dragVerPass" :loading="loading" @click="onLogin">{{$t('auth.action.login')}}</el-button>
        </el-form-item>
        <div class="tip">
          <el-button type="text" @click="onGoFindPassword">{{$t('auth.action.forgetPwd')}}</el-button>
          <div class="rigister">
            {{$t('auth.m_login.noAccount')}}
            <el-button type="text" @click="onGoRegister">{{$t('auth.action.register')}}</el-button>
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import DragVerify from 'vue-drag-verify'
import { mapState, mapMutations, mapActions } from 'vuex'
import userApi from '@/api/user'
export default {
  components: {
    DragVerify
  },
  data() {
    return {
      vierificationCodeUrl: '',
      loading: false,
      dragVerPass: false,
      model: {
        userName: '',
        passWord: '',
        verificationCode: '111',
        uuid: '111'
      },
      err: {
        show: false,
        msg: ''
      }
    }
  },
  methods: {
    ...mapMutations([
      'loginDialogShowSet',
      'tokenSet',
      'userSet'
    ]),
    ...mapActions(['getCurrentUserInfo']),
    getVierificationCode() {
      userApi.getVierificationCode().then(res => {
        this.vierificationCodeUrl = `data:image/png;base64,${res.img}`
        this.model.uuid = res.uuid
      })
    },
    onPasscallback() {
      this.dragVerPass = true;
    },
    onLogin(){
      if (!this.dragVerPass) {
        return;
      }
      this.loading = true
      this.err.show = false
      userApi.login(this.model).then(res => {
        this.loading = false
        if (!res.status) {
          this.err.msg = res.message
          this.err.show = true
          // this.getVierificationCode()
          return
        }
        this.tokenSet(res.data.token)
        this.getCurrentUserInfo().then(res => {
          let path = this.$route.query.redirect || 'home'
          this.$router.push(path)
        })
      }).catch(err => {
        this.loading = false
      })
    },
    onGoFindPassword() {
      this.$router.push('/auth?type=find-password')
    },
    onGoRegister() {
      this.$router.push('/auth?type=register')
    }
  },
  mounted() {
    // this.getVierificationCode()
  }
}
</script>

<style lang="less" scoped>
.box {
  width: 430px;
  .box-title {
    margin-bottom: 40px;
    .sub-title {
      margin-bottom: 16px;
      color: #858e9b;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 2px;
    }
    .title {
      color: rgb(31, 41, 46);
      font-size: 32px;
      font-weight: normal;
    }
  }
  .err-tip {
    margin-bottom: 22px;
  }
  .tip {
    display: flex;
    justify-content: space-between;
    /deep/ .el-button {
      padding: 0;
    }
  }
  .vcode-img {
    width: 100%;
    height: 40px;
    border: 1px solid #DCDFE6;
  }

  /deep/ .drag_verify .dv_handler {
    height: 40px!important;
    width: 40px!important;
  }
}
</style>
