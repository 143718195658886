<template>
  <div class="box">
    <div class="box-title">
      <div class="sub-title">{{$t('word.siteName')}}</div>
      <h3 class="title">{{$t('auth.m_register.name')}}</h3>
    </div>
    <div class="box-body">
      <el-alert
        class="error-tip"
        v-show="error.show"
        :title="error.message"
        effect="dark"
        type="error">
      </el-alert>
      <el-form ref="form" :model="model" :rules="rules">
        <template v-if="step === 1">
          <el-form-item label="" prop="email">
            <el-input type="text" v-model="model.email" :placeholder="$t('auth.m_register.inputEmail')" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" :loading="sendEmail.loading" style="width:100%;" @click="onSendEmail">{{$t('auth.action.sendVerCode')}}</el-button>
          </el-form-item>
          <div class="tip">{{$t('auth.m_register.alreadyHasAccount')}}<el-button type="text" @click="onGoLogin">{{$t('auth.action.login')}}</el-button></div>
        </template>
        <template v-if="step === 2">
          <el-form-item label="" prop="code">
            <el-input v-model="model.code" :placeholder="$t('auth.m_register.verificationCode')" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="" prop="userName">
            <el-input v-model="model.userName" :placeholder="$t('auth.m_register.account')" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="" prop="pass">
            <el-input type="password" v-model="model.pass" :placeholder="$t('auth.m_register.inputPassword')" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="" prop="pass2">
            <el-input type="password" v-model="model.pass2" :placeholder="$t('auth.m_register.inputPasswordRepeat')" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" style="width:100%;" @click="onRegSubmit">{{$t('auth.action.submit')}}</el-button>
          </el-form-item>
          <el-form-item>
            <el-checkbox v-model="useAgreementIsAgree" >
              {{$t('auth.m_register.alreadyReadAndAgree')}}
              <el-button type="text" @click="onUseAgreementShow">《{{$t('auth.m_register.useAgreement')}}》</el-button>
            </el-checkbox>
          </el-form-item>
          <transition name="el-zoom-in-bottom">
            <use-agreement v-if="useAgreementShow" @on-close="onUseAgreementClose"></use-agreement>
          </transition>
        </template>
      </el-form>
    </div>
  </div>
</template>

<script>
import * as commonApi from '@/api/common'
import * as userApi from '@/api/user'
import pattern from '@/config/pattern'
import UseAgreement from '../agreement/use'
export default {
  components: {
    UseAgreement
  },
  data() {
    let validateCode= (rule, value, callback) => {
      let passkey = parseInt(value) * 9527;
      if (passkey !== this.model.ex_passWordKey) {
        callback(new Error('验证码错误'));
      } else {
        callback();
      }
    };
    let validatePwd2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.model.pass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      error: {
        show: false,
        message: ''
      },
      step: 1,
      useAgreementShow: false,
      useAgreementIsAgree: false,
      sendEmail: {
        loading: false
      },
      model: {
        email: '',
        userName: '', // 账户名
        code: '',
        pass: '',
        pass2: '',
        ex_passWordKey: '',
      },
      rules: {
        userName: [
          {required: true, message: '请输入', trigger: 'blur'},
        ],
        email: [
          {required: true, message: '请输入', trigger: 'blur'},
          {pattern: pattern.email, message: '邮箱格式不正确', trigger: 'blur'}
        ],
        code: [
          {required: true, message: '请输入', trigger: 'blur'},
          {validator: validateCode, trigger: 'blur'}
        ],
        pass: [
          {required: true, message: '请输入', trigger: 'blur'}
        ],
        pass2: [
          {required: true, message: '请输入', trigger: 'blur'},
          {validator: validatePwd2, trigger: 'blur'}
        ]
      }
    }
  },
  methods: {
    onSendEmail() {
      this.$refs['form'].validate(async valid => {
        if (!valid) {
          return;
        }
        this.sendEmail.loading = true;
        let { code, msg, passWordKey } = await commonApi.sendEmail({
          email: this.model.email,
          do: 'reg'
        })
        this.sendEmail.loading = false;
        if(code === '1') {
          this.model.ex_passWordKey = parseInt(passWordKey);
          this.$message.success('验证码已发送您的邮箱,请查收');
          this.step = 2
        } else {
          this.$message.error('发送失败,请检查邮箱是否正确');
        }
      });
    },
    async onRegSubmit() {
      this.$refs['form'].validate(async valid => {
        if (!valid) {
          return;
        }
        if (!this.useAgreementIsAgree) {
          this.$message.info('请同意使用条款');
          return;
        }
        let { status, message } = await userApi.registerUser({
          userName: this.model.userName,
          userTrueName: '',
          passWord: this.model.pass,
          email: this.model.email,
          tel: '',
          address: '',
          webUnit: '',
          gender: 0
        });
        if(status) {
          this.error.show = false;
          this.$message.success('注册成功，请登录');
          this.$router.push('/auth?type=login')
        } else {
          this.error.show = true;
          this.error.message = message
        }
      });
    },
    onGoLogin() {
      this.$router.push('/auth?type=login')
    },
    onUseAgreementShow() {
      this.useAgreementShow = true
    },
    onUseAgreementClose() {
      this.useAgreementShow = false
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  width: 430px;
  .box-title {
    margin-bottom: 40px;
    .sub-title {
      margin-bottom: 16px;
      color: #858e9b;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 2px;
    }
    .title {
      color: rgb(31, 41, 46);
      font-size: 32px;
      font-weight: normal;
    }
  }
  .error-tip {
    margin-bottom: 22px;
  }
  .tip {
    /deep/ .el-button {
      padding: 0;
    }
  }
  /deep/ .el-button--text {
    padding: 0;
  }
}
</style>
