<template>
  <el-card class="box-card">
  <div slot="header" class="header">
    <h3>使用条款</h3>
    <el-button type="info" size="mini" icon="el-icon-close" circle @click="onClose"></el-button>
  </div>
  <div class="main">
    <ul>
      <li>如果你想注册成为科研实验数据管理网站用户，请务必阅读本使用协议（以下简称“本协议”）并在注册过程中表明你同意接受本协议。</li>
      <li><strong>1.</strong> 科研实验数据管理网站 用户指在本站注册并验证注册邮件地址的用户。用户可以使用个人空间、迷你博客、相册、群组和分享等基本功能。</li>
      <li>
        <p><strong>2.</strong> 一旦注册成为科研实验数据管理网站用户，即代表你保证：</p>
        <ul>
          <li class="indent">(a) 你提交的个人信息是真实、准确、完整的；</li>
          <li class="indent">(b) 你会不断更新个人资料，以符合及时、详尽、准确的要求；</li>
          <li class="indent">(c) 你在使用服务时不会违反任何适用的国家法律或法规；</li>
          <li class="indent">(d) 你在使用服务时不会违反科研实验数据管理网站一切有效的管理办法和规定；</li>
        </ul>
      </li>
      <li>
        <p><strong>3.</strong> 用户的个人资料受到科研实验数据管理网站的保护。科研实验数据管理网站承诺不会在未获得用户许可的情况下擅自将用户的个人资料信息出租或出售给任何第三方，但以下情况除外：</p>
        <ul>
          <li class="indent">(a) 用户同意让第三方共享资料；</li>
          <li class="indent">(b) 用户同意公开其个人资料，享受相应的产品和服务；</li>
          <li class="indent">(c) 科研实验数据管理网站需要听从法庭传票、法律命令或遵循法律程序；</li>
          <li class="indent">(d) 科研实验数据管理网站发现用户违反了本站服务条款或本站其他使用规定。更多关于隐私保护的内容请查看科研实验数据管理网站的隐私声明；</li>
        </ul>
      </li>
      <li><strong>4.</strong> 用户应随时根据实际情况更新个人资料。帐号相关信息不得影射党和国家领导人或者含有不雅含义，对违反者将予以删除帐号。</li>
      <li><strong>5.</strong> 用户在科研实验数据管理网站发文或使用任何服务时，以及在自己的个人资料中，均不能对他人进行骚扰或人身攻击、包含侮辱性言辞、引起不必要的纠纷、影射党和国家领导人或含有其他不恰当的内容。如果违犯上述情形，将视情况予以警告、封禁部分权限直至删除帐号。</li>
      <li><strong>6.</strong> 用户不得使用本站的帐号从事非法商业行为或发表猥亵、色情、反党反政府言论或者其他不当言论。违者将封禁部分权限直至删除帐号。</li>
      <li><strong>7.</strong> 用户不得利用本站帐号、个人资料、迷你博客、分享、照片、群组和私信等服务进行直接或者间接商业宣传，否则将视情节轻重给予警告、封禁部分权限直至删除帐号的处罚；对于蓄意为某一商业机构进行多个商业宣传的，将视情节轻重予以警告、封禁部分权限直至删除帐号的处罚。</li>
      <li><strong>8.</strong> 用户必须保证拥有上传之照片和文字等作品的著作权或已获得合法授权，用户必须保证在本站的上传行为未侵犯任何第三方之合法权益。否则，将由用户本人承担由此带来的一切法律责任。</li>
      <li><strong>9.</strong> 用户不得手动或者使用特殊程序对本站系统进行恶意冲击。对于恶意冲击系统并可能危及系统稳定运行的用户，直接删除帐号。</li>
      <li><strong>10.</strong> 用户有权力和责任对站内出现的违反国家法律法规以及有关站规的事件进行举报。</li>
      <li><strong>11.</strong> 科研实验数据管理网站用户帐号代表用户个人，由于用户发布消息或者其他站上交流引起的法律上的或者经济上的责任，科研实验数据管理网站不予负担其中任何形式的责任，用户的言论文责自负。</li>
      <li><strong>12.</strong> 用户应保护好科研实验数据管理网站帐号和密码安全，对任何人利用你的帐号和密码所进行的活动负全部责任。因科研实验数据管理网站无法对非法或未经用户授权使用的帐号的行为作出甄别，因此科研实验数据管理网站不承担任何责任。</li>
      <li><strong>13.</strong> 用户帐号仅代表使用者个人，不得转借、售卖或共用帐号。违者将视情节受到警告、封禁部分权限直至删除帐号的处罚。对于有意泄露帐号密码者，视同共用帐号从重处理。</li>
      <li><strong>14.</strong> 科研实验数据管理网站 有权随时修改本协议中的任何条款。任何修改自发布之时起生效。如果你在修改发布后继续使用科研实验数据管理网站的服务，即表示你同意遵守对本协议所作出的修改。欢迎随时查看本协议，以确保了解所有相关的最新修改。如果你不同意相关修改，请你离开本站并立即停止使用本站服务。</li>
      <li><strong>15.</strong> 本协议解释权归科研实验数据管理网站，有关事项与其他声明或协议互为补充。若有冲突，以科研实验数据管理网站 公布的最新协议为准。本协议自公布之日起施行。</li>
    </ul>
  </div>
</el-card>
</template>

<script>
export default {
  methods: {
    onClose() {
      this.$emit('on-close')
    }
  }
}
</script>

<style lang="less" scoped>
.box-card {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  border-radius: 0;
  z-index: 10;
  /deep/ .el-card__header {
    flex: 0 0 auto;
  }
  /deep/ .el-card__body {
    flex: 0 1 auto;
    padding: 40px 20px;
    overflow: auto;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .main {
    margin: 0 auto;
    width: 1200px;
    line-height: 1.5em;
    >ul {
      >li {
        margin-bottom: 20px;
        strong {
          font-weight: bold;
        }
      }
      >li:last-child {
        margin-bottom: 0;
      }
    }
    .indent {
      text-indent: 1em;
    }
  }
}
</style>
